<template>
  <div class="wrapper">
    <div v-if="!offerSelection">
      <div class="page_container">
        <BackComponent title="Special Offers" />
        <div class="enter-code">
          <h6>{{ $t('enterRedeemCode') }}</h6>
          <div class="code-search">
            <TextInput
              v-model="msisdn"
              placeholder="Redeem Code"
            />
            <div class="claim">
              <button>{{ $t('claim') }}</button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="page_container">
          <CategorySelector
            :categories="categoryList"
            :activeCategory="activeCategory"
            :offerSelection="false"
            @update:activeCategory="setCategory"
          />
          <div class="search">
            <LeaderboardSelect
              :options="leaderboardOptions"
              @update:selectedOption="handleSelection"
            />
          </div>
        </div>

        <div class="progress-offers">
          <div
            v-for="(promotion, index) in promotions"
            :key="index"
            class="progress-content"
          >
            <div class="combo">
              <p>{{ promotion.combo }}</p>
              <span :class="{ expire: promotion.isExpired }">
                {{ promotion.isExpired ? "Expire" : "Active" }}
              </span>
            </div>
            <div class="deadline">
              <span>{{ promotion.description }}</span>
              <span>until {{ promotion.deadline }}</span>
            </div>
            <div class="progress-track">
              <div class="level" :style="{ width: promotion.progress + '%' }">
                <span class="level-number">{{ promotion.progress }}%</span>
              </div>
            </div>
            <div class="view-progress">
              <span>
                {{ promotion.currentAmount }} /
                <b>{{ promotion.totalAmount }} XOF</b>
              </span>
              <span class="red" @click="selectPromotion(promotion)"
                >View Progress</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <SingleOffer
      v-if="offerSelection"
      :offer="offerSelection"
      @close="offerSelection = null"
    />
  </div>
</template>

<script>
import TextInput from "../ui/TextInput.vue";
import CategorySelector from "../ui/categorySelect/CategorySelect.vue";
import LeaderboardSelect from "../ui/select/LeaderBoard.vue";
import SingleOffer from "./SingleOffer.vue";

export default {
  name: "SpecialOffer",
  data() {
    return {
      activeCategory: "all",
      categoryList: [
        { label: "All", value: "all" },
        { label: "Sports", value: "sports" },
        { label: "Games", value: "casino" },
      ],
      leaderboardOptions: [
        { label: "All bonus", value: "bonus" },
        { label: "Global", value: "global" },
      ],
      promotions: [
        {
          combo: "BetCombo",
          description: "Get 100% to 5,000 XOF on your first deposit",
          deadline: "09/06/2024",
          progress: 75,
          currentAmount: 3500,
          totalAmount: 5000,
          isExpired: false,
        },
        {
          combo: "BetCombo",
          description: "Get 100% to 5,000 XOF on your first deposit",
          deadline: "09/06/2024",
          progress: 50,
          currentAmount: 2500,
          totalAmount: 5000,
          isExpired: true,
        },
      ],
      offerSelection: null,
    };
  },
  components: {
    LeaderboardSelect,
    TextInput,
    BackComponent: () => import("@/components/ui/BackComponent"),
    SingleOffer,
    CategorySelector,
  },
  methods: {
    setCategory(category) {
      this.activeCategory = category;
    },
    selectPromotion(promotion) {
      this.offerSelection = promotion;
    },
    handleSelection(selectedOption) {
      this.selectedOption = selectedOption;
    },
  },
};
</script>

<style src="./index.css" scoped></style>
