<template>
  <div class="single-offer">
    <div class="back">
      <a @click="$emit('close')">
        <img src="../../../public/img/icons/arrow-left.svg" alt="arrow left" />
      </a>
    </div>
    <div class="offer-container">
      <div class="page_container">
        <div>
          <h6>Welcome Bonus • Default</h6>
        </div>

        <div class="progress-track">
          <div class="level" :style="{ width: progressPercentage + '%' }">
            <span class="level-number">{{ progressPercentage }}%</span>
          </div>
        </div>
      </div>

      <div class="steps">
        <div
          v-for="step in steps"
          :key="step.number"
          :class="{ 'fade-step': step.number === 5 }"
          @click="toggleStep(step.number)"
          class="step"
        >
          <div>
            <p>{{ step.title }}</p>
            <span>{{ step.description }}</span>
          </div>
          <div>
            <img
              :src="
                isStepActive(step.number)
                  ? '/img/icon/tick-circle.svg'
                  : '/img/icon/record.svg'
              "
              alt="tick circle"
            />
          </div>
        </div>
      </div>

      <div class="termsandcondition">
        <p>Terms & Conditions</p>
        <span>
          Lorem ipsum dolor sit amet consectetur. Dapibus vitae justo egestas
          metus. Habitasse arcu mi ipsum eget massa. Amet feugiat amet varius
          orci placerat aliquam nisl in in. Eget quis scelerisque odio amet.
          Arcu feugiat turpis faucibus iaculis purus urna viverra. Malesuada non
          viverra lectus amet nisl nulla tempus. Erat aenean ipsum pellentesque
          lorem viverra. Volutpat convallis praesent purus cras. Quam quis neque
          tellus lacus quis risus nibh in. Lectus sit nunc viverra at.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SingleOffer",
  data() {
    return {
      completedSteps: [1, 2],
      steps: [
        {
          number: 1,
          title: "Step 1",
          description: "Get 100% to 5,000 XOF on your first deposit",
        },
        {
          number: 2,
          title: "Step 2",
          description: "Get 100% to 5,000 XOF on your first deposit",
        },
        { number: 3, title: "Step 3", description: "Bet and get the Bonus." },
        {
          number: 4,
          title: "Step 4",
          description: "Get 100% to 5,000 XOF on your first deposit",
        },
        { number: 5, title: "Step 5", description: "Bet and get the Bonus." },
      ],
    };
  },
  computed: {
    progressPercentage() {
      return Math.floor((this.completedSteps.length / this.steps.length) * 100);
    },
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isStepActive(stepNumber) {
      return this.completedSteps.includes(stepNumber);
    },
    toggleStep(stepNumber) {
      if (this.isStepActive(stepNumber)) {
        this.completedSteps = this.completedSteps.filter(
          (step) => step !== stepNumber
        );
      } else {
        this.completedSteps.push(stepNumber);
      }
    },
  },
};
</script>

<style src="./index.css" scoped></style>
