import { render, staticRenderFns } from "./CategorySelect.vue?vue&type=template&id=4206a164&scoped=true"
import script from "./CategorySelect.vue?vue&type=script&lang=js"
export * from "./CategorySelect.vue?vue&type=script&lang=js"
import style0 from "./index.css?vue&type=style&index=0&id=4206a164&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4206a164",
  null
  
)

export default component.exports