<template>
  <div class="leaderboard-wrapper">
    <select class="select" @change="handleChange">
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    handleChange(event) {
      const value = event.target.value;
      this.$emit('update:selectedOption', value);
    },
  },
};
</script>

<style scoped>
.leaderboard-wrapper {
  padding: 8px 20px 16px;
}

.select {
  outline: none;
  width: 100%;
  border: none;
  padding: 8px 10px;
  border-radius: 4px;
  background: #f5f5f5;
  color: #8c8787;
}
</style>
