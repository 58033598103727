<template>
  <div v-if="!selectedPromotion" class="select-sport">
    <button
      v-for="category in categories"
      :key="category.value"
      :class="{ active: activeCategory === category.value }"
      @click="setCategory(category.value)"
    >
      {{ category.label }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      required: true,
      default: () => [],
    },
    activeCategory: {
      type: String,
      default: 'all',
    },
    selectedPromotion: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setCategory(category) {
      this.$emit('update:activeCategory', category);
    },
  },
};
</script>

<style src="./index.css" scoped></style>
